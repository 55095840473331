/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useScreen from 'hooks/useScreen';
import IconTick from 'components/icons/iconTick';
import IconHeart2 from 'components/icons/iconHeart2';
import IconLightningBoltFilled from 'components/icons/IconLightningBoltFilled';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import MaterialThumbnailWrapper from './MaterialThumbnailWrapper';

const DEFAULT_TOGGLE_FLYOUT = () => {};

const MaterialThumbnailUI = ({
  customToggleFlyOut = false,
  customToggleFlyOutData = null,
  fullHeightThumbnail = false,
  image,
  isDisabled = false,
  isFavMaterial = false,
  isInFlashSale = false,
  isRound = false,
  isSelected = false,
  label,
  materialApplicableForProduct,
  materialId,
  onClick,
  onDblClick,
  onHover,
  toggleFlyOut = DEFAULT_TOGGLE_FLYOUT,
  variant,
}) => {
  const isTouchDevice = useIsTouchDevice();
  const { isDesktop, isMobile } = useScreen();

  const hasClickHandler = !!onClick;

  const shouldTriggerHover =
    !isMobile && !!onHover && !!materialApplicableForProduct;

  const onMouseEnter = useCallback(() => {
    if (isDesktop) {
      if (customToggleFlyOut && customToggleFlyOutData) {
        toggleFlyOut(true, customToggleFlyOutData);
      } else {
        toggleFlyOut(true, materialId);
      }
    }

    if (shouldTriggerHover) {
      onHover();
    }
  }, [
    isDesktop,
    shouldTriggerHover,
    customToggleFlyOut,
    customToggleFlyOutData,
    toggleFlyOut,
    materialId,
    onHover,
  ]);

  const onMouseLeave = useCallback(() => {
    if (isDesktop) {
      toggleFlyOut(false, materialId);
    }
  }, [isDesktop, toggleFlyOut, materialId]);

  return (
    <MaterialThumbnailWrapper
      hasClickHandler={hasClickHandler}
      fullHeightThumbnail={fullHeightThumbnail}
      variant={variant}
    >
      <div
        className={classNames(
          'border-2 border-solid border-white rounded-lg h-full w-full',
          'first:shadow-[inset_0_0_0_1px_#f6f6f6,inset_0_0_0_4px_#fff]',
          {
            '!border-0': variant === 'PDPShownInUI--grey-background',
            'shadow-[inset_0_0_0_1px_#737373,inset_0_0_0_4px_#fff]': isSelected,
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          onClick={onClick ?? undefined}
          onDoubleClick={!isTouchDevice && onDblClick ? onDblClick : undefined}
          className={classNames(
            'items-center rounded-lg flex h-full justify-center [transition:box-shadow_25ms_ease-in] w-full',
            {
              '!rounded-[50%]':
                variant === 'PDPShownInUI' ||
                variant === 'PDPShownInUI--grey-background',
              '!shadow-[inset_0_0_0_1px_#f1f1f1,inset_0_0_0_2px_#f1f1f1]':
                variant === 'PDPShownInUI--grey-background',
              'relative !shadow-[inset_0_0_0_1px_#262626,inset_0_0_0_3px_#fff]': isDisabled,
              '!shadow-[inset_0_0_0_1px_#262626,inset_0_0_0_4px_#fff]': isSelected,
              'rounded-full': isRound,
              'shadow-[inset_0_0_0_1px_#fff,inset_0_0_0_4px_#fff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-brand':
                hasClickHandler && !isRound,
              'hover:shadow-[inset_0_0_0_1px_#737373,inset_0_0_0_4px_#fff]':
                hasClickHandler && !isRound && !isSelected,
              '!rounded-none': variant === 'PDPFabricUpChargeModal',
            }
          )}
          style={
            image
              ? {
                  background: `url(${image}?auto=format%2Ccompress&w=50&h=50&dpr=2&cs=srgb&rect=0,0,100,100) 0% 0% / 100% 100% no-repeat`,
                }
              : undefined
          }
          aria-checked={isSelected}
          aria-label={onClick ? `Select ${label}` : label}
          role={hasClickHandler ? 'radio' : undefined}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={hasClickHandler ? '0' : undefined}
          title={onClick ? `Select ${label}` : label}
        >
          <div
            className={classNames('hidden', {
              'block z-[1] [&_svg]:absolute [&_svg]:bottom-2 [&_svg]:right-2 [&_svg]:w-[.6rem] [&_svg]:h-[.6rem]': isFavMaterial,
              '[&_svg]:w-[.6rem] [&_svg]:h-[.6rem]':
                isFavMaterial && isSelected,
            })}
          >
            <IconHeart2 fill="white" color="white" />
          </div>

          {isSelected && (
            <IconTick
              className="MaterialThumbnailUI__icon"
              height="22px"
              width="22px"
            />
          )}

          {isDisabled ? (
            <>
              <div className="absolute left-0 top-0 bg-[hsla(0,0%,100%,.4)] w-full h-full z-50" />
              <div className="absolute left-0 top-0 w-full h-full z-[51] [background:linear-gradient(to_top_left,transparent_0,transparent_calc(50%_-_.8px)_,#fff_50%,transparent_calc(50%_+_.8px)_,transparent)] rounded-lg" />
            </>
          ) : null}

          {isInFlashSale ? (
            <div className="w-4 h-4 absolute top-[7px] left-2">
              <IconLightningBoltFilled color="white" height={13} width={12} />
            </div>
          ) : null}
        </div>
      </div>
    </MaterialThumbnailWrapper>
  );
};

MaterialThumbnailUI.propTypes = {
  customToggleFlyOut: PropTypes.bool,
  customToggleFlyOutData: PropTypes.object,
  fullHeightThumbnail: PropTypes.bool,
  image: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFavMaterial: PropTypes.bool,
  isInFlashSale: PropTypes.bool,
  isRound: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  materialApplicableForProduct: PropTypes.bool,
  materialId: PropTypes.number,
  onClick: PropTypes.func,
  onDblClick: PropTypes.func,
  onHover: PropTypes.func,
  toggleFlyOut: PropTypes.func,
  variant: PropTypes.oneOf([
    'PDPCustomerPhotosReviewsModal',
    'PDPShownInUI',
    'PDPShownInUI--grey-background',
  ]),
};

export default MaterialThumbnailUI;
