import React, { memo } from 'react';
import classNames from 'classnames';

import RatioContainer from 'components/consumer/RatioContainer';

const MaterialThumbnailWrapper = ({
  children,
  hasClickHandler,
  fullHeightThumbnail,
  variant,
}) => (
  <RatioContainer
    ratio={1}
    className={classNames('MaterialThumbnailUI__wrapper', {
      'hover:cursor-pointer': hasClickHandler,
    })}
    wrapperClassName={classNames('MaterialThumbnailUI__outer-wrapper', {
      'h-full': fullHeightThumbnail,
      '!pt-0 h-[59px]': variant === 'PDPCustomerPhotosReviewsModal',
    })}
  >
    {children}
  </RatioContainer>
);

export default memo(MaterialThumbnailWrapper);
