import React, { memo } from 'react';

import Placeholder from 'components/consumer/Placeholder';
import MaterialThumbnailWrapper from './MaterialThumbnailWrapper';

const MaterialThumbnailPlaceholder = ({ fullHeightThumbnail, variant }) => (
  <MaterialThumbnailWrapper
    fullHeightThumbnail={fullHeightThumbnail}
    variant={variant}
  >
    <Placeholder
      loading
      type="rect"
      className="border-2 border-solid border-white rounded-lg h-full w-full cursor-auto shadow-[inset_0_0_0_5px_#fff]"
    />
  </MaterialThumbnailWrapper>
);

export default memo(MaterialThumbnailPlaceholder);
