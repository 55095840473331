import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import avo from 'analytics/avo';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import MaterialThumbnailUI from './MaterialThumbnailUI';
import MaterialThumbnailPlaceholder from './MaterialThumbnailPlaceholder';

const DEFAULT_FABRIC_FAV_PROPS = {};

const MaterialThumbnail = ({
  color,
  customToggleFlyOut = false,
  customToggleFlyOutData = null,
  fabricFavProps = DEFAULT_FABRIC_FAV_PROPS,
  fullHeightThumbnail = false,
  image,
  isDisabled = false,
  isFavMaterial,
  isInFlashSale = false,
  isRound = false,
  isSelected,
  label,
  loading,
  materialApplicableForProduct = true,
  materialId,
  onClick,
  onHover,
  show = true,
  toggleFlyOut,
  upholsteryOptionId,
  variant,
}) => {
  const {
    actions: { setOptionAsFav, removeOptionAsFav } = {},
    data: { favProductData } = {},
  } = fabricFavProps;

  const favParams = useMemo(
    () =>
      setOptionAsFav
        ? {
            fabricId: materialId,
            fabricName: label,
            fabricColor: color,
            location: 'PDP',
            ...favProductData,
          }
        : {},
    [setOptionAsFav, materialId, label, color, favProductData]
  );

  const isTouchDevice = useIsTouchDevice();
  const lastTapRef = useRef(null);
  const DOUBLE_PRESS_DELAY = 300;

  const onClickHandler = useCallback(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();

      if (!isSelected) {
        if (typeof onClick === 'function') {
          onClick(upholsteryOptionId, materialId, 'customizer');
        }
      } else {
        if (!isTouchDevice || !setOptionAsFav || !removeOptionAsFav) {
          return;
        }
        // comes here for mobile/tablet double tap handling.
        if (
          lastTapRef?.current &&
          Date.now() - lastTapRef.current < DOUBLE_PRESS_DELAY
        ) {
          if (isFavMaterial) {
            removeOptionAsFav(materialId);
            avo.fabricUnfavorited(favParams);
          } else if (!isFavMaterial) {
            setOptionAsFav(materialId);
            avo.fabricFavorited(favParams);
          }

          lastTapRef.current = null;
        } else {
          lastTapRef.current = Date.now();
        }
      }
    },
    [
      isSelected,
      onClick,
      upholsteryOptionId,
      materialId,
      isTouchDevice,
      setOptionAsFav,
      removeOptionAsFav,
      isFavMaterial,
      favParams,
      DOUBLE_PRESS_DELAY,
    ]
  );

  const onDblClick = useCallback(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      if (isTouchDevice || !setOptionAsFav || !removeOptionAsFav) {
        return;
      }
      if (isSelected || !materialApplicableForProduct) {
        if (isFavMaterial) {
          if (typeof toggleFlyOut === 'function') {
            toggleFlyOut(false, materialId);
          }
          removeOptionAsFav(materialId);
          avo.fabricUnfavorited(favParams);
        } else {
          setOptionAsFav(materialId);
          avo.fabricFavorited(favParams);
        }
      }
    },
    [
      isTouchDevice,
      setOptionAsFav,
      removeOptionAsFav,
      isSelected,
      materialApplicableForProduct,
      isFavMaterial,
      toggleFlyOut,
      materialId,
      favParams,
    ]
  );

  const onHoverHandler = useCallback(
    () => onHover(upholsteryOptionId, materialId),
    [onHover, upholsteryOptionId, materialId]
  );

  if (loading) {
    return (
      <MaterialThumbnailPlaceholder
        fullHeightThumbnail={fullHeightThumbnail}
        variant={variant}
      />
    );
  }

  return (
    <MaterialThumbnailUI
      customToggleFlyOut={customToggleFlyOut}
      customToggleFlyOutData={customToggleFlyOutData}
      fullHeightThumbnail={fullHeightThumbnail}
      image={image}
      isDisabled={isDisabled}
      isFavMaterial={isFavMaterial}
      isInFlashSale={isInFlashSale}
      isRound={isRound}
      isSelected={isSelected}
      label={label}
      materialApplicableForProduct={materialApplicableForProduct}
      materialId={materialId}
      onClick={materialApplicableForProduct ? onClickHandler : undefined}
      onDblClick={onDblClick}
      onHover={onHover ? onHoverHandler : undefined}
      show={show}
      toggleFlyOut={toggleFlyOut}
      variant={variant}
    />
  );
};

MaterialThumbnail.propTypes = {
  color: PropTypes.string,
  customToggleFlyOut: PropTypes.bool,
  customToggleFlyOutData: PropTypes.object,
  fabricFavProps: PropTypes.object,
  fullHeightThumbnail: PropTypes.bool,
  image: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFavMaterial: PropTypes.bool,
  isInFlashSale: PropTypes.bool,
  isRound: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  materialApplicableForProduct: PropTypes.bool,
  materialId: PropTypes.number,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  show: PropTypes.bool,
  toggleFlyOut: PropTypes.func,
  upholsteryOptionId: PropTypes.number,
  variant: PropTypes.oneOf([
    'PDPCustomerPhotosReviewsModal',
    'PDPShownInUI',
    'PDPShownInUI--grey-background',
  ]),
};

export default MaterialThumbnail;
